<template>
  <div class="wrap">
    <div class="search">
      <div class="search-left">
        <div class="tabCard">
          <div
            :class="{ activeTab: is_complete == undefined }"
            @click="tabCardChange(-1)"
          >
            全部
          </div>
          <div
            :class="{ activeTab: is_complete == 0 }"
            style="
              border-left: 1px solid #e5e5e5;
              border-right: 1px solid #e5e5e5;
            "
            @click="tabCardChange(0)"
          >
            未完成
          </div>
          <div
            :class="{ activeTab: is_complete == 1 }"
            @click="tabCardChange(1)"
          >
            已完成
          </div>
        </div>
        <div class="gap">
          日期范围：
          <DatePicker
            type="daterange"
            format="yyyy-MM-dd"
            placement="bottom-end"
            placeholder="请选择日期"
            style="width: 220px"
            :editable="false"
            @on-change="dateChangeHandler"
          >
          </DatePicker>
          <!-- <DatePicker type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"></DatePicker> -->
        </div>
        <div class="gap task-type">
          <Select
            v-model="executor"
            style="width: 180px"
            clearable
            @on-change="initNFlist2"
            placeholder="飞手"
          >
            <Option
              v-for="item in user_list"
              :key="item.user_id"
              :value="item.user_id"
            >
              {{ item.name }}</Option
            >
          </Select>
        </div>
        <div class="gap task-type">
          <Select
            v-model="type_id"
            style="width: 180px"
            clearable
            @on-change="initNFlist2"
            placeholder="任务类型"
          >
            <Option
              v-for="item in taskTypeList"
              :key="item.type_id"
              :value="item.type_id"
            >
              {{ item.type_name }}</Option
            >
          </Select>
          <div @click="taskModel = !taskModel" class="task-type-edit">
            <Icon type="ios-create-outline" size="22" />
          </div>
        </div>
      </div>
      <div class="search-right">
        <Button type="info" @click="addNewTaskModel">新增任务</Button>
      </div>
    </div>
    <div class="tableList">
      <Table :columns="NFcolumns" :data="tableList"></Table>
      <div class="page" v-if="total > limit">
        <Page
          show-total
          :page-size="limit"
          :total="total"
          :current="page"
          @on-change="pageChange"
        />
      </div>
    </div>
    <!-- 任务类型编辑窗口 -->
    <Modal
      v-model="taskModel"
      title="任务类型管理"
      width="360"
      footer-hide
      @on-cancel="taskModelCancel"
    >
      <div>
        <Form
          ref="formInline"
          class="addTask"
          :model="formInline"
          :rules="ruleInline"
          inline
        >
          <FormItem prop="newTaskType" style="width: 255px">
            <Input
              type="text"
              v-model="formInline.newTaskType"
              placeholder="新增类型"
            >
            </Input>
          </FormItem>
          <FormItem>
            <div @click="addTaskTypeBtn" class="addTaskTypeBtn">新增</div>
          </FormItem>
        </Form>
      </div>
      <div class="taskList">
        <div class="taskList-item" v-for="item in taskTypeList" :key="item.id">
          <div v-show="!item.edit">{{ item.type_name }}</div>
          <Input
            type="text"
            class="taskList-item-editInput"
            v-show="item.edit"
            v-model="item.editName"
            @on-enter="editInputTaskTypeName(item)"
            :placeholder="item.type_name"
          >
          </Input>
          <div>
            <Icon
              type="ios-create-outline"
              class="taskList-item-edit"
              @click="editTaskTypeName(item)"
              size="22"
            />
            <Icon
              type="md-close"
              @click="delTaskType(item.type_id)"
              class="taskList-item-del"
              size="22"
            />
          </div>
        </div>
      </div>
      <div slot="footer"></div>
    </Modal>

    <!-- 新增任务窗口 -->
    <Modal
      v-model="newTaskModel"
      title="新增任务"
      fullscreen
      @on-ok="newTaskModelOk"
      @on-cancel="newTaskModelCancel"
    >
      <div class="newTaskContainer">
        <div class="newTaskContainer-left">
          <div>
            <p><span class="red">*</span>任务名称</p>
            <Input
              v-model="newTaskName"
              maxlength="15"
              show-word-limit
              placeholder="此处填写任务名称"
            />
          </div>
          <div>
            <p>单位</p>
            <TeamListCascader
              @change_Team="addTaskchangeTeamData"
              class="addTeamInput"
              style="width: 300px"
            />
          </div>
          <div>
            <p><span class="red">*</span>任务类型</p>
            <Select
              v-model="newType_id"
              clearable
              ref="missionType"
              placeholder="任务类型"
            >
              <Option
                v-for="item in addTaskTypeList"
                :key="item.type_id"
                :value="item.type_id"
              >
                {{ item.type_name }}</Option
              >
            </Select>
          </div>
          <div>
            <p><span class="red">*</span>执行人</p>
            <Select v-model="newExecutor" clearable placeholder="飞手">
              <Option
                v-for="item in addTaskuser_list"
                :key="item.user_id"
                :value="item.user_id"
              >
                {{ item.name }}</Option
              >
            </Select>
          </div>
          <div>
            <p><span class="red">*</span>任务时间</p>
            <DatePicker
              type="daterange"
              format="yyyy-MM-dd"
              placement="bottom-end"
              placeholder="请选择日期"
              :editable="false"
              style="width: 100%"
              :value="dateRange"
              @on-change="newDateChangeHandler"
            >
            </DatePicker>
          </div>
          <div>
            <p>备注</p>
            <Input
              v-model="newTaskNameRemark"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 5 }"
              placeholder="此处填写任务备注"
            ></Input>
          </div>
          <div v-show="newTaskAreaName">
            <p>任务区域</p>
            <!-- <Input v-model="newTaskAreaName"
                   placeholder="此处填写任务区域" /> -->
            <p>{{ newTaskAreaName }}</p>
          </div>
          <div>
            <Button type="info" @click="saveTask">保存</Button>
          </div>
        </div>
        <div id="map"></div>
      </div>
      <div slot="footer"></div>
    </Modal>

    <!-- 查看弹窗 -->
    <DetailTask
      ref="detailTask"
      :task_id="task_id"
      :taskTypeList="taskTypeList"
      :user_list="user_list"
      :NF="NFpath"
      title="查看任务"
    >
      <!-- <div slot="map"
           id="amap">
      </div> -->
    </DetailTask>
    <!-- 删除弹窗 -->
    <Modal v-model="delModal" title="提示" @on-ok="delConfirm">
      <p>确认删除吗？</p>
    </Modal>
  </div>
</template>

<script>
import TeamListCascader from '@/components/statistics/commonComponents/TeamCascader_task.vue'
import DetailTask from './detailTask.vue'
import api from '@/utils/api.js'
import { GetNotFlyArea } from '@/utils/javaApi.js'

export default {
  name: 'task',
  components: { TeamListCascader, DetailTask },
  data: function () {
    return {
      dateRange: [],
      task_id: null, // 传给详情的
      newTaskAreaName: '',
      newNF: [], // 鼠标绘制多边形坐标
      mouseTool: '', // 鼠标绘制工具
      polygonEditor: '', // 多边形编辑工具
      NFpath: [], // 获取的虚拟护栏，全国统一
      map: '',
      newTaskName: '',
      taskModel: false, // 任务类型弹窗
      is_complete: undefined,
      newTaskModel: false, // 新增任务弹窗
      newExecutor: '', // 新建任务里的执行人Id
      newType_id: '', // 新建任务里的任务类型
      newTaskNameRemark: '',
      executor: '',
      user_list: [],
      addTaskuser_list: [],
      taskTypeList: [],
      addTaskTypeList: [],
      start_time: '',
      newStart_time: '',
      newEnd_time: '',
      teamID: -1, // 团队id(值为-1时,获取下级团队所有)
      addTaskTeamID: -1, //新增任务里的团队id
      type_id: '',
      tableList: [],
      Range: [1, 2, 3],
      page: 1,
      limit: 15,
      total: 1,
      images: [],
      delModal: false,
      NFcolumns: [
        {
          title: '任务名称',
          key: 'task_name',
          align: 'center',
        },
        {
          title: '任务类型',
          key: 'type_name',
          align: 'center',
        },
        {
          title: '开始时间',
          key: 'start_time',
          align: 'center',
        },
        {
          title: '截止时间',
          key: 'end_time',
          align: 'center',
        },
        {
          title: '执行人',
          key: 'name',
          align: 'center',
        },
        {
          title: '状态',
          key: 'is_complete',
          width: 120,
          align: 'center',
          render: (h, params) => {
            let str = params.row.is_complete === 1 ? '已完成' : '未完成'
            let color = params.row.is_complete === 1 ? '#00ED6D' : '#F56C6C'
            return h(
              'span',
              {
                style: {
                  //原生的属性 props:{}为自定义属性
                  color: color,
                },
              },
              str
            )
          },
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                  },
                  style: {
                    marginRight: '15px',
                  },
                  on: {
                    click: () => {
                      this.detailBtn(params.row)
                    },
                  },
                },
                '查看'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                  },
                  on: {
                    click: () => {
                      this.del(params.row.id)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      formInline: {
        newTaskType: '',
      },
      ruleInline: {
        newTaskType: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 3,
            max: 15,
            message: '字数在3-15之间',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {
    // if (sessionStorage.getItem('page')) {
    //   this.page = sessionStorage.getItem('page');
    // }
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    if (sessionStorage.getItem('team_id') === null) {
      this.initNFlist()
    } else {
      this.teamID = Number(sessionStorage.getItem('team_id'))
      this.initNFlist()
    }
    this.$_bus.$off('change_Team').$on('change_Team', (value) => {
      this.teamID = value
      this.getTaskList('LIST')
      this.pageChange(1)
    })
    this.getTaskList('LIST')
    this.initMap() // 初始化地图
    this.getNotFlyArea() // 获取全国虚拟护栏
  },
  watch: {
    addTaskTeamID(val) {
      this.getTaskList('addTask')
    },
    newTaskModel(val) {
      if (val == true) {
        this.initDraw() // 新增时绘制多边形
        this.getTaskList('addTask')
      }
    },
  },
  methods: {
    delConfirm() {
      const params = { task_id: this.task_id, client: 'web' }
      this.$post(api.taskDelete(), params).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer)
          this.initNFlist()
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    saveTask() {
      // 判断是否绘制，进入编辑模式后绘制完成
      if (this.polygonEditor) {
        if (!this.sumbit()) return
        // 关闭编辑功能，并获取编辑后的对象
      } else {
        this.$Message.warning('未绘制任务区域')
      }
    },
    sumbit() {
      if (!this.newTaskName.trim()) {
        this.$Message.error('任务名称不能为空')
        return false
      }

      if (this.newTaskName.trim().length < 3) {
        this.$Message.error('任务名称长度不符合要求 3,15')
        return false
      }

      if (!this.newType_id) {
        this.$Message.error('任务类型不能为空')
        return false
      }
      if (!this.newExecutor) {
        this.$Message.error('执行人不能为空')
        return false
      }
      if (!this.newStart_time || !this.newEnd_time) {
        this.$Message.error('任务时间不能为空')
        return false
      }

      if (this.mouseTool) {
        this.mouseTool.close(true)
        this.mouseTool = ''
      }
      if (this.polygonEditor) {
        this.polygonEditor.close()
        this.polygonEditor = ''
      }

      let params = {
        client: 'web',
        task_name: this.newTaskName.trim(),
        type_id: this.newType_id,
        executor: this.newExecutor,
        task_area: JSON.stringify(this.newNF),
        start_time: this.newStart_time,
        end_time: this.newEnd_time,
        note: this.newTaskNameRemark,
      }
      this.$post(api.taskAdd(), params).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer)
          this.newTaskModel = false
          this.page = 1
          //   sessionStorage.setItem('page', 1); // 设置初始翻页
          this.initNFlist()

          this.newNF = []
          this.mouseTool = ''
          this.newTaskName = ''
          this.newType_id = ''
          this.newExecutor = ''
          //   this.start_time = '';
          //   this.end_time = '';
          this.dateRange = []
          this.newTaskNameRemark = ''
          this.newTaskAreaName = ''
          this.newTaskModel = false
        } else {
          this.$Message.error(res.msg_customer)
          this.initDraw()
        }
      })
    },
    // 绘制任务区
    initDraw() {
      // 新增
      this.mouseTool = new AMap.MouseTool(this.map) // 注册鼠标绘制工具
      //监听draw事件完成
      this.mouseTool.on('draw', this.getDraw)
      this.mouseTool.polygon({
        // extData: { type: 5 },
        strokeColor: '#FF33FF',
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillColor: '#1791fc',
        fillOpacity: 0.4,
        strokeStyle: 'solid',
      })
    },
    getAddsByPosition(mylnglat) {
      let that = this
      this.map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          //city: '010',
        })
        let lnglat = mylnglat
        geocoder.getAddress(lnglat, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            that.newTaskAreaName = result.regeocode.formattedAddress
            // result为对应的地理位置详细信息
          }
        })
      })
    },
    getDraw(e, d) {
      let that = this
      //构造折线编辑对象，并开启折线的编辑状态
      if (d) {
        // console.log("d");
        // let p = [];
        // d.forEach((ele) => {
        //   p.push(new AMap.LngLat(ele.lng, ele.lat));
        // });
        // let polyline = new AMap.Polygon({
        //   path: p,
        //   fillColor: "#c41a16",
        //   strokeColor: "#ff0000",
        //   fillOpacity: 0.3,
        // });
        // this.map.add(polyline);
        // this.map.plugin(["AMap.PolyEditor"], function () {
        //   that.polygonEditor = new AMap.PolyEditor(that.map, polyline);
        //   that.polygonEditor.open();
        //   that.newNF = []; // 初始化新虚拟护栏
        // });
      } else {
        this.mouseTool.close()
        that.getAddsByPosition([e.obj.getPath()[0].R, e.obj.getPath()[0].Q])

        //构造折线编辑对象，并开启折线的编辑状态
        this.map.plugin(['AMap.PolyEditor'], function () {
          that.polygonEditor = new AMap.PolyEditor(that.map, e.obj)
          that.polygonEditor.open()
          //that.newNF = []; // 初始化新虚拟护栏
        })
      }

      that.polygonEditor.on('addnode', function (event) {
        let ll = []
        ll.push(event.lnglat.R) // 修改地图api
        ll.push(event.lnglat.Q)
        that.getAddsByPosition(ll)
      })

      that.polygonEditor.on('adjust', function (event) {
        let ll = []
        ll.push(event.lnglat.R) // 修改地图api
        ll.push(event.lnglat.Q)
        that.getAddsByPosition(ll)
      })

      // 编辑监听
      that.polygonEditor.on('end', function (event) {
        event.target.getPath().forEach((ele) => {
          that.newNF.push({ lat: ele.lat, lng: ele.lng })
        })
      })
    },
    // 获取虚拟护栏
    getNotFlyArea() {
      this.$post(GetNotFlyArea(), {
        team_id: this.userInfo.team_id,
        showTeam: 0,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = []
            element.local_data.forEach((ele) => {
              path.push(new AMap.LngLat(ele.lng, ele.lat))
            })
            this.NFpath.push(path)
          })
        }
      })
    },
    // 初始化虚拟护栏
    initNFzone() {
      this.polygon = new AMap.Polygon({
        path: this.NFpath,
        fillColor: '#c41a16',
        strokeColor: '#ff0000',
        fillOpacity: 0.3,
      })
      this.map.add(this.polygon)
    },
    //  添加新任务
    addNewTaskModel() {
      this.newTaskModel = true
      this.initNFzone()
    },
    initMap() {
      let layer = []
      if (this.$online) {
        layer = [new AMap.TileLayer()]
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
            },
            zIndex: 10,
          }),
        ]
      }
      this.map = new AMap.Map('map', {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 16, //初始化地图层级
        center: this.$Center, //初始化地图中心点
        layers: layer,
      })
      this.map.setMapStyle('amap://styles/whitesmoke')
    },
    newTaskModelOk() {},
    newTaskModelCancel() {
      if (this.mouseTool) {
        this.mouseTool.close(true)
        this.mouseTool = ''
      }
      if (this.polygonEditor) {
        this.polygonEditor.close()
        this.polygonEditor = ''
      }

      this.newNF = []
      this.mouseTool = ''
      this.newTaskName = ''
      this.newType_id = ''
      this.newExecutor = ''
      //   this.start_time = '';
      //   this.end_time = '';
      this.dateRange = []
      this.newTaskNameRemark = ''
      this.newTaskAreaName = ''
      this.newTaskModel = false
    },
    tabCardChange(num) {
      if (num == -1) {
        this.is_complete = undefined
      } else {
        this.is_complete = num
      }
      this.pageChange(1)
    },
    editInputTaskTypeName(item) {
      this.$post(api.taskTypeEdit(), {
        type_id: item.type_id,
        type_name: item.editName,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success('修改成功!')
          item.type_name = item.editName
          this.getTaskList('LIST')
        } else {
          item.editName = item.type_name
          this.$Message.warning(res.msg_customer)
        }
      })
    },
    editTaskTypeName(item) {
      this.taskTypeList.map((el) => (el.edit = false))
      item.edit = !item.edit
    },
    addTaskTypeBtn() {
      this.$refs['formInline'].validate((valid) => {
        if (valid) {
          this.$post(api.taskTypeAdd(), {
            type_name: this.formInline.newTaskType.trim(),
            team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$Message.success('添加成功!')
              this.formInline.newTaskType = ''
              this.getTaskList('LIST')
            } else {
              this.$Message.warning(res.msg_customer)
            }
          })
        }
      })
    },
    delTaskType(id) {
      this.$post(api.taskTypeDelete(), {
        type_id: id,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success('删除成功!')
          this.getTaskList('LIST')
        }
      })
    },
    taskModelCancel() {
      this.$refs['formInline'].resetFields()
      this.initNFlist()
    },

    detailBtn(item) {
      this.task_id = item.id
      this.$refs.detailTask.showModal = true
    },
    del(id) {
      if (this.userInfo.is_manager === 1) {
        this.delModal = true
        this.task_id = id
      } else {
        this.$Message.warning('您没有操作权限')
      }
    },
    // 翻页
    pageChange(p) {
      this.page = p
      //   sessionStorage.setItem('page', p);
      this.initNFlist()
    },
    // 获取表格列表
    initNFlist() {
      let params = {
        page: this.page,
        team_id: this.teamID > 0 ? this.teamID : this.userInfo.team_id,
        limit: this.limit,
        end_time: this.end_time || '',
        start_time: this.start_time,
        type_id: this.type_id,
        is_complete: this.is_complete,
        executor: this.executor,
      }
      this.$post(api.getTaskList(), params).then((res) => {
        if (res.code == 1) {
          this.total = res.data.total
          this.tableList = res.data.data
        }
      })
    },
    dateChangeHandler(e) {
      if (e) {
        this.start_time = e[0]
        this.end_time = e[1] || ''
      }
      this.page = 1
      //   sessionStorage.setItem('page', 1); // 设置初始翻页
      this.initNFlist()
    },
    newDateChangeHandler(e) {
      if (e) {
        this.newStart_time = e[0]
        // this.newEnd_time = e[1];
        let isdate = new Date(e[1].replace(/-/g, '/')) //把日期字符串转换成日期格式
        isdate = new Date((isdate / 1000 + 86400) * 1000) //日期加1天
        if (isdate.getDate() < 10) {
          this.newEnd_time =
            isdate.getFullYear() +
            '-' +
            (isdate.getMonth() + 1) +
            '-0' +
            isdate.getDate() //把日期格式转换成字符串
        } else {
          this.newEnd_time =
            isdate.getFullYear() +
            '-' +
            (isdate.getMonth() + 1) +
            '-' +
            isdate.getDate() //把日期格式转换成字符串
        }
      }
    },

    addTaskchangeTeamData(value) {
      this.addTaskTeamID = value[value.length - 1]
      this.$refs.missionType.clearSingleSelect() // 清空任务类型选择
    },

    // 选择器
    initNFlist2() {
      this.page = 1
      //   sessionStorage.setItem('page', 1); // 设置初始翻页
      this.initNFlist()
    },
    // 获取任务类型和飞手人员
    getTaskList(type) {
      let team_id
      if (type === 'LIST') {
        team_id = this.teamID > 0 ? this.teamID : this.userInfo.team_id
      }
      if (type === 'addTask') {
        team_id =
          this.addTaskTeamID > 0 ? this.addTaskTeamID : this.userInfo.team_id
      }
      this.$post(api.taskListAndPerson(), {
        team_id: team_id,
      }).then((res) => {
        if (res.code === 1) {
          let data = res.data.type_list
          const temp = data.map((el) => {
            el.edit = false
            el.editName = el.type_name
          })
          if (type === 'LIST') {
            this.user_list = res.data.user_list
            this.taskTypeList = data
          }
          if (type === 'addTask') {
            this.addTaskuser_list = res.data.user_list
            this.addTaskTypeList = data
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.addTeamInput {
  /deep/ .ivu-cascader .ivu-input {
    height: 32px;
  }
}
.red {
  color: red;
  padding-right: 5px;
}
#map {
  width: 100%;
  height: 780px;
}
.newTaskContainer {
  display: flex;
  .newTaskContainer-left {
    width: 20%;
    padding-right: 16px;
    & > div {
      padding: 10px 0;
      & > p {
        padding: 10px 0;
        // color: #999999;
      }
    }
  }
}
.gap {
  margin-left: 25px;
}
.addTask {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .addTaskInput {
    flex: 1;
  }
  .addTaskTypeBtn {
    width: 50px;
    text-align: right;
    cursor: pointer;
    color: #2d8cf0;
  }
}
.taskList {
  .taskList-item {
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .taskList-item-edit,
  .taskList-item-del {
    cursor: pointer;
  }
  .taskList-item-del {
    margin-left: 10px;
  }
  .taskList-item-editInput {
    width: 250px;
  }
}
.wrap {
  background-color: #383838;
  height: 100%;
  padding: 10px 20px;
  .task-type {
    display: flex;
    align-items: center;
    .task-type-edit {
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .search {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    .search-left {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
    }
    .search-right {
      width: 95px;
    }
  }
  .tableList {
    overflow: hidden;
    .page {
      padding: 10px 20px;
      float: right;
    }
    /deep/ .ivu-table th {
      background: #333;
      color: #fff;
    }
    /deep/ .ivu-table {
      background: unset;
    }
    /deep/ .ivu-table td {
      background: #1e1e1e;
      color: #fff;
    }
    /deep/ .ivu-table td,
    .ivu-table th {
      border-bottom: 1px solid #000;
    }
    /deep/ .ivu-table th {
      border-bottom: 1px solid #000;
    }
  }
  /deep/ .green {
    color: #00ed6d;
  }
  /deep/ .red {
    color: #f56c6c;
  }
}

.tabCard {
  display: flex;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  overflow: hidden;
  div {
    width: 100px;
    padding: 4px 0px;
    text-align: center;
    cursor: pointer;
  }
  .activeTab {
    background-color: #2196f3;
    color: #fff;
  }
}
</style>