<template>
  <div>
    <Modal v-model="showModal"
           :title="title"
           fullscreen
           @on-ok="newTaskModelOk"
           @on-cancel="newTaskModelCancel">
      <div class="newTaskContainer">
        <div class="newTaskContainer-left">
          <div>
            <p><span class="red">*</span>任务名称</p>
            <Input v-model="newTaskName"
                   maxlength="15"
                   show-word-limit
                   placeholder="此处填写任务名称" />
          </div>
          <div>
            <p><span class="red">*</span>任务类型</p>
            <Select v-model="newType_id"
                    clearable
                    placeholder="任务类型">
              <Option v-for="item in taskTypeList"
                      :key="item.type_id"
                      :value="item.type_id">
                {{ item.type_name }}</Option>
            </Select>
          </div>
          <div>
            <p><span class="red">*</span>执行人</p>
            <Input :value="newExecutorName"
                   readonly></Input>
            <!-- <Select v-model="newExecutor"
                    clearable
                    placeholder="飞手">
              <Option v-for="item in user_list"
                      :key="item.user_id"
                      :value="item.user_id">
                {{ item.name }}</Option>
            </Select> -->
          </div>
          <div>
            <p><span class="red">*</span>任务时间</p>
            <DatePicker type="daterange"
                        format="yyyy-MM-dd"
                        placement="bottom-end"
                        placeholder="请选择日期"
                        style="width: 100%"
                        :editable="false"
                        :value="dateRange"
                        @on-change="newDateChangeHandler">
            </DatePicker>
          </div>
          <div>
            <p>备注</p>
            <Input v-model="newTaskNameRemark"
                   type="textarea"
                   :autosize="{minRows: 2,maxRows: 5}"></Input>

          </div>
          <div>
            <p>任务区域</p>
            <p>{{ newTaskAreaName }}</p>
          </div>
        </div>
        <div class="newTaskContainer-right">
          <!-- <div v-if="title == '查看任务'" class="detailMap">
            <slot name="map"></slot>
          </div> -->
          <div class="detailMap"
               v-if="title == '查看任务'">
            <div id="amap"></div>
            <p>照片</p>
            <div class="bigImgWrap">
              <viewer :images="images">
                <div v-for="(src, index) in images"
                     :key="index">
                  <img :src="src" />
                </div>

              </viewer>
            </div>
            <p>视频</p>
            <div class="bigImgWrap">
              <div>
                <div v-for="(el, i) in videos"
                     :key="i">
                  <img :src="el.first_frame"
                       @click="bigVideoSrc = el.video;vedioModel=true" />
                </div>

                <!-- <img v-for="(el, i) in videos"
                     @click="bigVideoSrc = el.video;vedioModel=true"
                     :src="el.first_frame"
                     :key="i" /> -->
              </div>
            </div>
          </div>

        </div>
      </div>
      <div slot="footer"></div>
    </Modal>
    <!-- 视频弹窗 -->
    <Modal v-model="vedioModel"
           footer-hide
           width="700"
           title="视频"
           @on-ok="vedioModelOk"
           @on-cancel="vedioModelCancel">
      <div class="vedio">
        <video controls
               v-if="bigVideoSrc"
               ref="video"
               :src="bigVideoSrc"
               autoplay>
          您的浏览器不支持Video标签
        </video>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from '@/utils/api.js';
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    task_id: {
      type: String,
    },
    taskTypeList: {
      type: Array,
    },
    user_list: {
      type: Array,
    },
    NF: {
      type: Array,
    },
  },
  data() {
    return {
      showModal: false,
      newTaskName: '',
      newType_id: '',
      newExecutor: '',
      newExecutorName: '',
      newTaskNameRemark: '',
      newTaskAreaName: '暂无该地区区域信息',
      dateRange: [],
      map: '',
      images: [],
      videos: [],
      bigVideoSrc: '',
      vedioModel: false,
      task_area: [],
      trajectory: [], // app 返回的轨迹
    };
  },
  watch: {
    async showModal(val) {
      this.newTaskName = '';
      this.newType_id = '';
      this.newExecutor = '';
      if (this.map) {
        this.map.destroy();
        this.map = '';
      }
      this.newExecutorName = '';
      this.newTaskNameRemark = '';
      this.newTaskAreaName = '暂无该地区区域信息';
      this.dateRange = [];
      this.images = [];
      this.bigVideoSrc = '';
      this.vedioModel = false;
      this.task_area = [];
      this.trajectory = [];
      if (val == true && this.task_id) {
        await this.getDetail();
        this.initMap();
        if (this.task_area.length)
          this.getAddsByPosition([
            this.task_area[0].lng,
            this.task_area[0].lat,
          ]);
        if (this.trajectory.length > 0) {
          this.drawFlyPolyline();
        }
      }
    },
  },
  methods: {
    vedioModelOk() {},
    vedioModelCancel() {},
    newTaskModelOk() {},
    newTaskModelCancel() {},
    newDateChangeHandler(e) {
      if (e) {
        this.newStart_time = e[0];
        this.newEnd_time = e[1];
      }
    },
    getIdByName(typeName, arr) {
      return arr.filter((el) => el.type_name === typeName);
    },
    async getDetail() {
      let res = await this.$post(api.taskDetail(), {
        task_id: this.task_id,
      });

      if (res.code === 1) {
        this.task_area = res.data.task_area;
        if (res.data.trajectory == null) {
          this.trajectory = [];
        } else {
          //   this.trajectory = JSON.parse(res.data.trajectory);
          this.trajectory = res.data.trajectory;
          console.log(this.trajectory);
        }

        this.images = res.data.image;
        this.videos = res.data.video;
        this.newTaskName = res.data.task_name;
        let arr = this.getIdByName(res.data.type_name, this.taskTypeList);
        if (arr.length) {
          this.newType_id = arr[0].type_id;
        }
        console.log('look this', this.newType_id);

        this.newExecutor = this.user_list.filter(
          (el) => el.name === res.data.name
        );
        if (this.newExecutor.length) {
          this.newExecutor = this.newExecutor[0].user_id;
        }
        this.newExecutorName = res.data.name;
        this.dateRange = [
          res.data.start_time.split(' ')[0],
          res.data.end_time.split(' ')[0],
        ];
        this.newTaskNameRemark = res.data.note;
      } else {
        this.$Message.warning(res.msg_customer);
      }
    },
    // 地图初始化
    initMap() {
      let layer = [];
      if (this.$online) {
        layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
            },
            zIndex: 10,
          }),
        ];
      }
      this.map = new AMap.Map('amap', {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 16, //初始化地图层级
        center: this.$Center, //初始化地图中心点
        layers: layer,
      });
      this.map.setMapStyle('amap://styles/whitesmoke');
      this.drawArea();
      // 加载虚拟护栏
      this.initNFzone();
    },
    // 初始化虚拟护栏
    initNFzone() {
      let polygon = new AMap.Polygon({
        path: this.NF,
        fillColor: '#c41a16',
        strokeColor: '#ff0000',
        fillOpacity: 0.3,
      });
      this.map.add(polygon);
    },
    drawArea() {
      let path = [];
      this.task_area.map((el) => {
        path.push([el.lng, el.lat]);
      });
      let polygon_area = new AMap.Polygon({
        path: path,
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
      });
      this.map.add(polygon_area);
      this.map.setFitView([polygon_area]);
    },
    drawFlyPolyline() {
      // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
      let path = [];
      this.trajectory.map((el) => {
        path.push([el.lng, el.lat]);
      });

      // 创建折线实例
      let flyPolyline = new AMap.Polyline({
        path: path,
        isOutline: true,
        outlineColor: '#ffeeff',
        borderWeight: 3,
        strokeColor: '#3366FF',
        strokeOpacity: 1,
        strokeWeight: 6,
        // 折线样式还支持 'dashed'
        strokeStyle: 'solid',
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: 'round',
        lineCap: 'round',
      });

      // 将折线添加至地图实例
      this.map.add(flyPolyline);
      this.map.setFitView(flyPolyline);
    },
    getAddsByPosition(mylnglat) {
      let that = this;
      this.map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          //city: '010',
        });
        let lnglat = mylnglat;
        geocoder.getAddress(lnglat, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            that.newTaskAreaName = result.regeocode.formattedAddress;
            // result为对应的地理位置详细信息
          } else {
            that.newTaskAreaName = '暂无该地区区域信息';
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vedio {
  display: flex;
  justify-content: center;
  video {
    max-width: 100%;
    max-height: 70vh;
  }
}
.detailMap {
  #amap {
    width: 80vw;
    height: 400px;
  }
  p {
    color: #999999;
    padding: 20px 0;
  }
  .bigImgWrap > div {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 150px;
      height: 150px;
      padding-right: 20px;
      flex-shrink: 0;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding-bottom: 20px;
    }
  }
}
.red {
  color: red;
  padding-right: 5px;
}
.newTaskContainer {
  display: flex;
  .newTaskContainer-left {
    width: 20vw;
    padding-right: 16px;
    & > div {
      padding: 10px 0;
      & > p {
        padding: 10px 0;
        // color: #999999;
      }
    }
  }
  .newTaskContainer-sright {
    width: 80vw;
  }
}
</style>